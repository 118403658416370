/* Mobile Styles */
@media (max-width: 600px) {
    .drillr-logo {
        font-size: 3rem;
    }

    body {
        padding: var(--margin-small);
    }

    .button {
        font-size: var(--font-size-small);
        padding: var(--margin-small);
    }

    .header {
        flex-direction: column;
        align-items: center;
    }

    .header-links {
        display: flex;
        font-size: var(--font-size-small);
        margin-top: var(--margin-medium);
        margin-bottom: var(--margin-medium);
    }
}

/* Tablet Styles */
@media (min-width: 601px) and (max-width: 1024px) {
    .drillr-logo {
        font-size: 4rem;
    }

    body {
        padding: var(--margin-medium);
    }

    .button {
        font-size: var(--font-size-medium);
        padding: var(--margin-small) var(--margin-medium);
    }

    .header {
        flex-direction: column;
        align-items: center;
    }

    .header-links {
        display: flex;
        font-size: var(--font-size-small);
        margin-top: var(--margin-large);
        margin-bottom: var(--margin-medium);
    }


}


/* Desktop Styles */
@media (min-width: 1025px) {
    .drillr-logo {
        font-size: 5rem;
    }

    body {
        padding: var(--margin-large);
    }

    .button {
        font-size: var(--font-size-large);
        padding: var(--margin-medium) var(--margin-large);
    }

    .header-links {

        display: flex;
    }
}