@import 'variables.css';

/* Logo */


.drillr-logo {
    /* border: yellow 2px solid; */
    font-size: var(--font-size-xlarge);
    /* ... other properties ... */
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);



}

a {
    text-decoration: none;
}

.logo-png {
    width: 200px;

}

/* Checkbox Group */
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--margin-small);
    margin-bottom: var(--margin-medium);
}

.checkbox-button {
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

.checkbox-button input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-button label {
    display: inline-block;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-surface-100);
    border: 2px solid var(--color-surface-200);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: var(--font-size-small);
    text-align: center;
}

.checkbox-button input[type="checkbox"]:checked+label {
    background-color: var(--color-primary-100);
    /* background-color: var(--gradient-bg); */
    color: var(--color-white);
    border-color: var(--color-primary-200);
}

.checkbox-button input[type="checkbox"]:disabled+label {
    background-color: var(--color-surface-400);
    color: var(--color-surface-600);
    cursor: not-allowed;
    border-color: var(--color-surface-500);
}

.checkbox-button input[type="checkbox"]:not(:disabled):hover+label {
    background-color: var(--color-button-background-hover);
}

.checkbox-button input[type="checkbox"]:checked:not(:disabled):hover+label {
    background-color: var(--color-primary-100);
}

/* Techniques List */
.techniques-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--margin-small);
    margin-top: var(--margin-medium);
}

.technique-button {
    text-transform: uppercase;
    background-color: var(--color-surface-100);
    border: 1px solid var(--color-surface-200);
    border-radius: 4px;
    padding: 5px 10px;
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--color-white);
}

.technique-button:hover {
    background-color: var(--color-button-background-hover);
}

.technique-button.selected {
    background-color: var(--color-button-background-selected);
    color: var(--color-white);
    border-color: var(--color-primary-200);
}

.technique-button .technique-type {
    font-size: var(--font-size-small);
    color: var(--color-surface-500);
}

.technique-button.selected .technique-type {
    color: var(--color-white);
}

/* Random Button */
.random-button {
    text-transform: uppercase;
    color: var(--color-button-text);
    font-size: var(--font-size-large);
    display: inline-block;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-button-background);
    border: 2px solid var(--color-button-border);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: var(--margin-large);
}

.random-button:hover {
    background-color: var(--color-button-background-hover);
    color: var(--color-white);
}

@media (max-width: var(--breakpoint-tablet)) {

    .checkbox-group,
    .techniques-list {
        gap: var(--margin-small);
    }

    .checkbox-button label,
    .technique-button,
    .random-button {
        padding: 8px 16px;
        font-size: var(--font-size-small);
    }
}

.random-button,
.technique-button {
    /* ... other properties ... */
    text-shadow: var(--retro-shadow);
    box-shadow: 0 0 3px var(--color-button-border);
}

.random-button:hover,
.technique-button:hover {
    /* ... other properties ... */
    box-shadow: 0 0 5px var(--color-button-border);
}

/* .video-container {
    position: relative;
    padding-bottom: 56.25%;

    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: var(--margin-large);
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    height: 0;
    overflow: hidden;
    margin: var(--margin-xlarge);
    /* make a shadow around the video */
    box-shadow: 0 0 70px black;
    border-radius: 20px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

}

@media screen and (max-width: 600px) {
    .video-container {
        margin: var(--margin-xlarge);
        width: 100vw;
        border-radius: 0;

    }

}

.technique-name {
    text-transform: uppercase;
    font-size: var(--font-size-xxlarge);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    text-align: center;
}


.edit-button {
    text-transform: uppercase;
    color: var(--color-button-text);
    font-size: var(--font-size-small);
    display: inline-block;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-button-background);
    border: 2px solid var(--color-button-border);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

li,
.step {
    font-size: var(--font-size-medium);
    margin: var(--margin-small) 0;
    list-style: circle;
}

.breadcrumb {
    text-transform: uppercase;
    font-size: var(--font-size-medium);
    color: var(--color-primary-300);
    /* margin: var(--margin-medium) 0; */
}

.description {
    font-size: var(--font-size-large);
    margin: var(--margin-medium) 0;
    text-align: center;
}

.glossary {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--margin-medium);

}

.glossary-list,
.glossary-list li {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    margin-bottom: var(--margin-xlarge);
}

h3 {
    margin-bottom: var(--margin-large);
}

h4 {
    font-size: var(--font-size-large);
    margin: var(--margin-medium) 0;
    text-transform: uppercase;
}

.glossary-term {
    font-size: var(--font-size-medium);
    margin: var(--margin-small) 0;
}

.glossary-definition {
    font-size: var(--font-size-small);
    margin: var(--margin-small) 0;
    text-transform: uppercase;
}

.glossary-notes {
    font-size: var(--font-size-small);
    margin: var(--margin-small) 0;
    color: var(--color-surface-700);
}

.search-input {
    max-width: 500px;
    width: 50vw;

    padding: 1.2rem;

    font-size: var(--font-size-medium);
    border: 2px solid var(--color-surface-200);
    border-radius: 5px;
    margin-bottom: 2.5rem;
    background-color: rgb(20, 20, 20);
    color: white;

}