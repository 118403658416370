@import 'variables.css';



a {
    text-decoration: none;
}

.logo-png {
    width: 200px;
}

/* Checkbox Group */
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--margin-small);
    margin-bottom: var(--margin-medium);
}

.checkbox-button {
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

.checkbox-button input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-button label {
    display: inline-block;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-surface-100);
    border: 2px solid var(--color-surface-200);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: var(--font-size-small);
}

.checkbox-button input[type="checkbox"]:checked+label {
    background-color: var(--color-primary-100);
    color: var(--color-white);
    border-color: var(--color-primary-200);
}

.checkbox-button input[type="checkbox"]:disabled+label {
    background-color: var(--color-surface-400);
    color: var(--color-surface-600);
    cursor: not-allowed;
    border-color: var(--color-surface-500);
}

.checkbox-button input[type="checkbox"]:not(:disabled):hover+label {
    background-color: var(--color-button-background-hover);
}

.checkbox-button input[type="checkbox"]:checked:not(:disabled):hover+label {
    background-color: var(--color-primary-100);
}

/* Models Grid */
.models-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--margin-small);
    margin-top: var(--margin-medium);
}

.model-button {
    text-transform: uppercase;
    background-color: var(--color-surface-100);
    border: 1px solid var(--color-surface-200);
    border-radius: 4px;
    padding: 10px 20px;
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--color-white);
    text-shadow: var(--retro-shadow);
    box-shadow: 0 0 3px var(--color-button-border);
}

.model-button:hover {
    background-color: var(--color-button-background-hover);
    box-shadow: 0 0 5px var(--color-button-border);
}

.model-button.selected {
    background-color: var(--color-button-background-selected);
    color: var(--color-white);
    border-color: var(--color-primary-200);
}

/* Selected Model */
.selected-model {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--margin-large);
    padding: var(--margin-medium);
    /* border: 2px solid var(--color-surface-200);
    border-radius: 10px; */
    background-color: var(--color-surface-50);
    color: var(--color-surface-900);
}

/* .selected-model h2 {
    font-size: var(--font-size-xlarge);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    text-align: center;
    margin-bottom: var(--margin-small);
} */


.summary {
    max-width: 800px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--margin-small);
    margin-top: var(--margin-medium);
    font-size: var(--font-size-medium);
}

.selected-model a {
    color: var(--color-primary-300);
    text-decoration: underline;
    font-size: var(--font-size-medium);
}

.selected-model a:hover {
    color: var(--color-primary-400);
}

/* Random Button */
.random-button {
    text-transform: uppercase;
    color: var(--color-button-text);
    font-size: var(--font-size-large);
    display: inline-block;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-button-background);
    border: 2px solid var(--color-button-border);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: var(--retro-shadow);
    box-shadow: 0 0 3px var(--color-button-border);
}

.random-button:hover {
    background-color: var(--color-button-background-hover);
    color: var(--color-white);
    box-shadow: 0 0 5px var(--color-button-border);
}

@media (max-width: var(--breakpoint-tablet)) {

    .checkbox-group,
    .models-grid {
        gap: var(--margin-small);
    }

    .checkbox-button label,
    .model-button,
    .random-button {
        padding: 8px 16px;
        font-size: var(--font-size-small);
    }
}


.learn-more-button {
    display: inline-flex;
    align-items: center;
    padding: var(--margin-small) var(--margin-medium);
    background-color: var(--color-button-background);
    color: var(--color-button-text);
    border: 2px solid var(--color-button-border);
    border-radius: 5px;
    text-decoration: none;
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-block: var(--margin-large);
}

.learn-more-button:hover {
    background-color: var(--color-button-background-hover);
    color: var(--color-white);
}

.learn-more-button svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    fill: currentColor;
}