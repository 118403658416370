@import 'variables.css';

* {
    margin: 0;
    padding: 0;
}


html,
body {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: var(--gradient-background-2);
    background: var(--gradient-background-2);
    background-size: cover;
    color: var(--color-white);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /* overscroll-behavior: none; */
}




.content {
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--margin-medium);
}

@media (max-width: var(--breakpoint-tablet)) {
    .content {
        padding: var(--margin-small);
    }
}

p {
    font-size: var(--font-size-large);
}

h2 {
    text-transform: uppercase;
    font-size: var(--font-size-xlarge);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    text-align: center;
    /* margin-top: var(--margin-medium); */
    margin-top: 0;
    text-transform: uppercase;
    font-size: var(--font-size-xxlarge);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    text-align: center;
}


h3 {
    text-transform: uppercase;
    font-size: var(--font-size-large);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    margin-top: 0;
    text-transform: uppercase;
    font-size: var(--font-size-xlarge);
    background: var(--gradient-text-body);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: var(--subtle-glow);
    text-align: center;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}