@import 'variables.css';



.header {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;




    /* border: red 2px solid; */
}

.header-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: var(--margin-large);
    text-transform: uppercase;
    /* border: green 2px solid; */
}

.header-links a {
    text-decoration: none;
    color: white;
    position: relative;
    /* Ensure the pseudo-element is positioned relative to the link */
}

.header-links a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    /* Adjust according to your design */
    width: 100%;
    height: 2px;
    /* Same as your border */
    background-color: white;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.header-links a.active-link::after {
    opacity: 1;
}



.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--margin-large);

    font-size: var(--font-size-small);

    & * {
        padding: var(--margin-medium);
    }

    .img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        filter: opacity(0.9);

    }

    & a {
        font-weight: bold;
        text-decoration: none;

        color: var(--color-white);
        text-transform: uppercase;
    }

}

.disclaimer {
    max-width: 600px;
}

.selected-technique {
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Ensure items are aligned at the start of the container */




}

@media (max-width: var(--breakpoint-tablet)) {
    .selected-technique {
        margin: var(--margin-large) auto;
    }
}


.edit-section {
    margin-top: var(--margin-medium);
    display: flex;
    justify-content: center;
}

.holding-message {
    margin: var(--margin-large) auto;
    text-align: center;
    text-transform: uppercase;
}

.password-input {
    background-color: #333;
    padding: 10px;
    color: white;
    border: 1px solid #555;
    font-size: 1.2rem;
    border-radius: 5px;
    margin: 20px;
}

.submit-button {
    background: var(--color-white);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}