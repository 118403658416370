:root {
    /* Base Colors */
    --color-black: #121212;
    --color-white: #f0f0f0;

    /* Grey Scale */
    --grey-100: #1a1a1a;
    --grey-200: #2a2a2a;
    --grey-300: #3a3a3a;
    --grey-400: #4a4a4a;
    --grey-500: #5a5a5a;
    --grey-600: #6a6a6a;

    /* Accent Colors */
    --accent-cyan: #4d415f;
    --accent-purple: #9858e9;
    --accent-gold: #ffd700;
    --accent-blue: #12ddca;

    /* Surface Colors */
    --color-surface-100: var(--grey-100);
    --color-surface-200: var(--grey-200);
    --color-surface-300: var(--grey-300);
    --color-surface-400: var(--grey-400);
    --color-surface-500: var(--grey-500);
    --color-surface-600: var(--grey-600);

    /* Primary Colors */
    --color-primary-100: var(--accent-cyan);
    --color-primary-200: var(--accent-purple);
    --color-primary-300: var(--accent-gold);

    /* Button Colors */
    --color-button-background: var(--grey-200);
    --color-button-border: var(--grey-400);
    --color-button-background-selected: var(--accent-cyan);
    --color-button-background-hover: var(--grey-300);
    --color-button-text: var(--color-white);

    /* Gradients */
    --gradient-background: linear-gradient(135deg, var(--grey-100) 0%, var(--grey-200) 100%);
    --gradient-background-2: linear-gradient(135deg, rgb(25, 23, 25) 0%, rgb(25, 28, 34) 100%);
    --gradient-text: linear-gradient(-20deg, var(--accent-blue), var(--accent-purple));
    --gradient-text-body: linear-gradient(45deg, var(--accent-blue), var(--accent-purple));
    --gradient-bg: linear-gradient(45deg, var(--accent-blue), var(--accent-purple));

    /* Sizes and Margins */
    --margin-small: 8px;
    --margin-medium: 16px;
    --margin-large: 34px;
    --margin-xlarge: 62px;

    --font-size-small: 0.8rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.2rem;
    --font-size-xlarge: 1.8rem;
    --font-size-xxlarge: 3rem;

    /* Responsive breakpoints */
    --breakpoint-tablet: 768px;
    --breakpoint-desktop: 1024px;

    /* Effects */
    --subtle-glow: 0 0 2px currentColor;
    --subtle-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}